<template>
  <div class="bg">
    <div class="top mb8">
      <h1>订单信息</h1>
      <div class="cl">
        <img src="../../../assets/img/inquiry_1.png" alt="" class="fl" />
        <div class="fl pbox">
          <p>私人医生服务费</p>
          <p>问诊医生：王涛</p>
          <p>问诊科室：呼吸科</p>
        </div>
        <div class="fr">300.00元</div>
      </div>
    </div>
    <div class="bot mb8">
      <h1>支付方式</h1>
      <van-radio-group v-model="radio">
        <van-radio name="1"
          ><div class="zf"><img src="../../../assets/img/wx.png" alt="" /> <span>微信支付</span></div></van-radio
        >
        <van-radio name="2"
          ><div class="zf">
            <img src="../../../assets/img/zfb.png" alt="" /> <span>支付宝支付</span>
          </div></van-radio
        >
      </van-radio-group>
    </div>
      <div class="btnbox">
        <van-button round type="info" size="large" @click="gonav('privatedoctorordeinfo')" color='rgba(29,174,252,1)'
          >去支付</van-button
        >
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio: "",
    };
  },
  methods: {
    gonav(url){
      this.$router.push({ name: url });

    } 
  },
};
</script>
<style lang="less" scoped>
.bg {
  .btnbox{
    padding: 40px;
    background: #fff;
  }
  .top {
    padding: 16px;
    background: #fff;
    h1 {
      font-size: 18px;
      font-weight: 400px;
      margin-bottom: 25px;
    }
    img {
      display: block;
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    p {
      margin-bottom: 8px;
    }
    .fr {
      color: rgba(251, 81, 81, 1);
    }
  }
  .bot {
    padding: 24px 16px;
    background: #fff;
    h1 {
      font-weight: 400;
      margin-bottom: 17px;
    }
    .van-radio{
      margin-bottom: 8px;
    }
    .zf{
      height: 40px;
      img{
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      line-height: 40px;
      span{
        vertical-align:text-bottom
      }
    }
  }
}
</style>
